@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

body{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.7;
  color: #ececee;
  background-color: #1f2029;
  overflow: hidden;
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg');
  background-position: center;
  background-repeat: repeat;
  background-size: 4%;
  height: 100vh;
  width: 100%;
}

.title{
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 42px;
  line-height: 1;
  color: #ffeba7;
  text-align: center;
  -webkit-text-stroke: 2px #ffeba7;
  text-stroke: 2px #ffeba7;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;

  position: absolute;
  left: 38%;
  top: 11%;
}


.file {
  position: absolute;
  left: 37%;
  top: 25%;

  align-items: center;
  background-color: #fee6e3;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.file:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.file:hover:after {
  transform: translate(0, 0);
}

.file:active {
  background-color: #ffdeda;
  outline: 0;
}

.file:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .file {
    padding: 0 40px;
  }
}


.folder {
  position: absolute;
  left: 52%;
  top: 25%;

  align-items: center;
  background-color: #fee6e3;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.folder:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.folder:hover:after {
  transform: translate(0, 0);
}

.folder:active {
  background-color: #ffdeda;
  outline: 0;
}

.folder:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .folder {
    padding: 0 40px;
  }
}



.button-28 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}


.corner{
  position: absolute;
  top: 7%;
  right: 20%;
}



.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}


.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}


.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}


.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}


.input{
  position: absolute;
  top: 61.5%;
  left: 36%;
  width: 400px;
  padding: 15px;
  font-size: 18px;

}


.encrypt{
  position: absolute;
  top: 75%;
  left: 39%;
}

.decrypt{
  position: absolute;
  top: 75%;
  left: 55%;
}

.drag_drop{
  position: absolute;
  top: 33%;
  left: 36%;
}


label {
  width: 400px;
  box-sizing: content-box;
}


.message{
  position: absolute;
  left: 33%;
  top: 30%;
  font-size: 14px;
  font-family: Arial, serif;
}

.chipper{
  position: relative;
  text-align: center;
  font-family: Arial, serif;
  font-size: 16px;
  top: 85vh;
  left: 13px;
}



.file_name{
  font-size: 18px;
}

@media (max-width: 600px){
  .title{
    font-size: 30px;
    left: 16%;

  }
  .line{
    position: absolute;
    width: 200px;
    top: 18%;
    left: 24%;
  }

  .drag_drop{
    left: 0;
  }

  label {
    width: 150px;
    box-sizing: content-box;
    left: 5.5%;
  }

  .input{
    left: 20%;
    width: 200px;
    padding: 15px;
    font-size: 16px;
  }

  .encrypt{
    left: 15%;
  }

  .decrypt{
    left: 64%;
  }

  .button-30{
    font-size: 12px;
  }

  .message{
    left: 6.5%;
  }

  .info_text{
    position: relative;
    font-size: 11px;
    right: 0;
  }


  .chipper{
    position: absolute;
    top: 86%;
    font-size: 14px;
  }

  .file_name{
    position: relative;
    font-size: 16px;
    left: 25px;
  }
}

@media (max-height: 500px){

  .line{
    top: 20%;
  }

  .input{
    top: 70%;
  }

  .encrypt{
    top: 85%;
  }

  .decrypt{
    top: 85%;
  }

  .chipper{
    opacity: 0;
  }

}
