/* Add a black background color to the top navigation */
.topnav {
    background-color: #333;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    font-weight: bold;

}

/* Style the links inside the navigation bar */
.topnav a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 18px 18px;
    text-decoration: none;
    font-family: Arial, serif;
    font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
    background-color: #ddd;
    color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
    background-color: #04AA6D;
    color: white;
}





@media (max-width: 600px){
    /* Style the links inside the navigation bar */
    .topnav a {
        font-size: 13px;
    }

}